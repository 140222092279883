import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import { Provider} from 'react-redux';
import { tabStore } from './src/states/store';
import * as WebBrowser from 'expo-web-browser';
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from './config';
import { screens } from './src/utils/ScreenUtils';
import WellcomeScreen from './src/screens/WellcomeScreen';
WebBrowser.maybeCompleteAuthSession();
const Stack = createNativeStackNavigator();

export const UserContext = React.createContext();

//calendar branch

const Body = () => {
  const [dimension, setDimension] = useState(Dimensions.get('window'));
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimension(window);
      }
    )
    return () => {
      subscription.remove();
    }
  }, []);
  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName={screens.wellcome}
      >
        <Stack.Screen
          name={screens.wellcome}
          component={WellcomeScreen}
          options={{ title: "Home" }}
          initialParams={{ "dimension": dimension }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default function App() {
  return (
    <Provider store={tabStore}>
      <Body />
    </Provider>
  );
}

